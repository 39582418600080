import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getEventById } from '../RealTimeDashboard/actions'
import { useSnackbar } from 'notistack';
import { registerAttendeeNew } from "../RegistroUsuario/actions"
import { v4 as uuid } from 'uuid';


const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const RegistroUsuario = props => {
  const { history } = props;

  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [eventId, setEventId] = useState("");
  const [errores, setError] = useState("");
  //const [data, setData] = useState();
  //const [textoSubmitButton, setTextoSubmitButton] = useState("Crear Evento");
  const [evento, setEvento] = useState("");

  const [values, setValues] = useState({
      name: '',
      occupancy: 0,
      eventDate: '',
      eventTime: '',
      eventAddress: ''
  });

  useEffect(() => {
    var arrayDeCadenas = window.location.pathname.split('/');

    if (arrayDeCadenas.length > 3) {
        var eventId = arrayDeCadenas[arrayDeCadenas.length - 1];

        setEventId(eventId);

        getEventById(eventId)
            .then(event => {
                setValues(
                    {
                        name: event.data.name,
                        occupancy: event.data.occupancy,
                        eventDate: new Date(event.data.eventDate).toISOString().slice(0, 10),
                        eventTime: event.data.eventTime,
                        eventAddress: event.data.eventAddress,
                    });

            })
            .catch(err => {
                console.log(err);
                enqueueSnackbar("No fue posible cargar la informacion del evento", { variant: 'error' });
            })
    }
}, [eventId]);


  /*
  useEffect(() => {
    
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);
*/
  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    const newAttendee = {
        nombres: formState.values.nombre,
        apellidos: formState.values.apellidos,
        email: formState.values.email,
        celular : formState.values.celular,
        nombreEmpresa: formState.values.nombreEmpresa,
        categoria: "VISITANTE",
        event: eventId,
        locale: "ES",
        guid: uuid()

    }

    registerAttendeeNew(newAttendee)
        .then(res => {console.log(res.data);history.push("/landing?&id=" + res.data)})        
        .catch(error => {
            //setdisableSubmit(false);
            enqueueSnackbar("No fue posible registrar el usuario", { variant: 'error' });
        });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                Jalisco el corazón de México
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSubmit}
              >
                <Typography
                  className={classes.title}
                  variant="h1"
                >
                  {values.name}
                </Typography>
                <br></br>
                <Typography
                  variant="h4"
                >
                  <b>Fecha </b>{values.eventDate} |  <b>Hora</b> {values.eventTime}
                </Typography>                      
                <Typography
                  variant="h4"
                >
                  <b>Direccion </b>{values.eventAddress}
                </Typography>         
                <br></br>                  
                <Typography
                  color="textSecondary"
                >
                  Capture su informacion por favor para el registrarse al evento
                </Typography>
                <TextField
                                autoFocus
                                fullWidth
                                className={classes.textField}
                                helperText="Por favor especifique su nombre"
                                label="Nombre"
                                name="nombre"
                                onChange={handleChange}
                                required
                                value={values.nombre}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                className={classes.textField}
                                helperText="Por favor especifique su apellido"
                                label="Apellido"
                                name="apellidos"
                                onChange={handleChange}
                                required
                                value={values.apellidos}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                className={classes.textField}
                                helperText="Por favor especifique su correo electrónico"
                                label="correo electrónico"
                                name="email"
                                type="email"
                                onChange={handleChange}
                                required
                                value={values.email}
                                variant="outlined"
                            />                            
                            <TextField
                                fullWidth
                                className={classes.textField}
                                helperText="Por favor especifique su numero de celular"
                                label="celular"
                                name="celular"
                                type="number"
                                onChange={handleChange}
                                required
                                value={values.celular}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                className={classes.textField}
                                helperText="Por favor especifique el nombre de su Agencia de Viajes"
                                label="Agencia de Viajes"
                                name="nombreEmpresa"
                                onChange={handleChange}
                                required
                                value={values.nombreEmpresa}
                                variant="outlined"
                            />

                <Button
                  className={classes.signInButton}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                   registrarse
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

RegistroUsuario.propTypes = {
  history: PropTypes.object
};

export default withRouter(RegistroUsuario);
