import axios from "axios";

// Get Atendee Details
export const getAttendeeByAttendeeId = (attendeeId) => {
    try {
      return axios.get("/api/attendees/" + attendeeId);
    } catch (error) {
      console.error(error)
    }
  };
  