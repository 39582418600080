import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter, useLocation  } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAttendeeByAttendeeId } from "../Landing/actions"
import { useSnackbar } from 'notistack';
import queryString from 'query-string'


const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Landing = props => {
  const { history } = props;
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [values, setValues] = useState({
      name: '',
      email: '',
      categoria: '',
      event:''
  });




  useEffect(() => {

    console.log(id);
    getAttendeeByAttendeeId(id)
    .then(res => setValues({ name: res.data.nombres, email: res.data.email, categoria: res.data.categoria, event: res.data.event }))
    .catch(err => {
        console.log(err);
        enqueueSnackbar("No fue posible cargar la informacion del invitado", { variant: 'error' });
    })

}, [id]);


  const handleRedirect = () => {
    history.push("/eventos/registro/" + values.event)
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant="h1"
              >
                Jalisco el corazón de México
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody} class="center">
                <Typography
                  className={classes.title}
                  variant="h1"
                >
                  Hola <b>{values.name}</b> tu registro fue completado satisfactoriamente 👏
                  <br></br><br></br>
                </Typography>
                <Typography
                  variant="h4"
                >
                  Tu código QR de acceso al evento fue enviado al correo <b>{values.email}</b>
                </Typography>
                <br>
                </br>
                <br>
                </br>
                <br></br>
                <Button
                  className={classes.signInButton}
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleRedirect}
                >
                   Registrar a otra persona ?
                </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Landing.propTypes = {
  history: PropTypes.object
};

export default withRouter(Landing);
