import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
//import { useHistory } from 'react-router-dom';
import MUIDataTable from "mui-datatables";

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button
} from '@material-ui/core';

import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const AttendeesTable = props => {
  const { className, ...rest } = props;
  
  const columns = ["Nombre", "Cargo","Empresa", "email", "celular", "id", "origen", "Fecha Registro"];

  
  const options = {
    filter: true,
    filterType: "dropdown",
    downloadOptions: {
      filename: 'SECTUR_asistencia.csv'
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    }
 };

 // option
const downloadOptions = {
  filename: "excel-format.csv",
  separator: ";",
  filterOptions: {
  useDisplayedColumnsOnly: true,
  useDisplayedRowsOnly: true,
  }
}
  

const onDownload = (buildHead, buildBody, columns, data) => {
  return "\uFEFF" + buildHead(columns) + buildBody(data); 
} 

  //console.log(className);
  //console.log(users);
 
  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);   

  useEffect(() => {   

    var arrayDeCadenas = window.location.pathname.split('/');
    var event_id = arrayDeCadenas[arrayDeCadenas.length - 1];

    const GetData = async () => {    
    const result = await axios.get("/api/attendees/preregistro/"+event_id);
	  setData(result.data);    
	}  

    GetData();    
    //console.log(data);  
}, []);  				

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  /*
  onDownload: (buildHead, buildBody, columns, data) => {
    return "\uFEFF" + buildHead(columns) + buildBody(data);
  }
*/


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <MUIDataTable
                title={"Asistentes pre-registrados al evento"}
                data={data}
                columns={columns}
                options={options}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

AttendeesTable.propTypes = {
  className: PropTypes.string
};

export default AttendeesTable;